<template>
    <b-row class="match-height">
      <b-col
lg="12"
> <b-card
      title="Confirm Material Request"
    >
      <b-form
          @submit.prevent="submitEditMaterialRequest"
>
        <b-row>
          <b-col cols="12">
            <b-button
            type="button"
            variant="primary"
            class="mr-1"
            @click="previous()"
            >
            <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
            />
            Back
            </b-button>
            <div class="devider" />
        <b-form-group>
          <label for="email">Number :</label>
          <input
            v-model="materialRequestNumber"
            type="text"
            name="Number"
            class="form-control"
            readonly
            >
        </b-form-group>
            <b-form-group
              label="To"
              label-for="material_request_to"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_to"
                v-model="materialRequestTo"
                placeholder="To."
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="From"
              label-for="material_request_cc"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_cc"
                v-model="materialRequestCc"
                placeholder="From"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Reason"
              label-for="material_request_reason"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_reason"
                v-model="materialRequestReason"
                placeholder="Reason"
                required="required"
                readonly
              />
            </b-form-group>
            <div
              >
                <b-row>
                  <b-col cols="6" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Item Unit
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Requested QTY
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Accepted QTY
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.material_request_detail_item_id"
                >
                  <br />
                  <b-col cols="6" class="text-center">
                    <b-list-group>
                      <b-list-group-item
                        v-if="item.item.item_picture"
                        :href="getFullUrl(item.item.item_picture)"
                        target="_blank"
                      >
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                      <b-list-group-item
                        v-if="item.item.item_picture == null"
                        href="#"
                      >
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{
                          item.item.unit
                            ? capitalizeWords(item.item.unit.unit_name)
                            : "-"
                        }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-form-input
                      v-model="item.qty"
                      min="1"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-form-input
                      v-if="materialRequestAccepted==false"
                      v-model="item.qty_accepted"
                      type="number"
                      placeholder="Qty"
                      required="required"
                      :max="item.qty"
                    />
                    <b-form-input
                      v-if="materialRequestAccepted==true"
                      v-model="item.qty_accepted"
                      placeholder="Qty"
                      min="1"
                      readonly
                    />
                    </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>
              </div>
              </b-col>
          <!-- submit and reset -->
          <b-col offset-md="12">
            <b-button
              v-if="materialRequestAccepted==0"
              type="submit"
              variant="primary"
              class="mr-1"
            :disabled="submitButtonDisabled"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const materialRequestNumber = ''
  const materialRequestTo = ''
  const materialRequestCc = ''
  const materialRequestReason = ''
  const materialRequestQtyAccepted = 0
  const materialRequestStatus = ''
  const materialRequestType = ''
  const materialRequestId = ''
  const materialRequestAccepted = false
  const submitButtonDisabled = false
  export default {
    components: {
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
     materialRequestId,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      materialRequestNumber,
      materialRequestTo,
      materialRequestCc,
      materialRequestReason,
      materialRequestQtyAccepted,
      materialRequestStatus,
      materialRequestAccepted,
      submitButtonDisabled,
      materialRequestType,
      }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
    getFullUrl(picturePath) {
      return `${this.apiUrl}/upload/${picturePath}`
    },
    getDetail() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const item = JSON.parse(localStorage.getItem("materialRequestDetail"))
      this.modalDetailShow = true
      this.materialRequestTo = item.material_request_to
      this.materialRequestCc = item.material_request_cc
      this.materialRequestReason = item.material_request_reason
      this.materialRequestId = item.material_request_id
      this.materialRequestNumber = item.material_request_number
      this.materialrequestIsAcceptedByWarehouse = item.material_request_is_accepted_by_warehouse
      this.materialrequestIsAcceptedBySupervisor = item.material_request_is_accepted_by_supervisor
      this.materialRequestType = item.material_request_is_center === true ? "Head Office" : "Local"
      this.materialrequestIsReceived = item.material_request_is_received !== null ? item.material_request_is_received : false
      const id = item.material_request_id
      const obj1 = {
        title: "Created",
        content: `Material Request Has been Created By ${
          item.requester.user_fullname
        } at ${this.dateSimple(
          item.material_request_created_time,
          "YYYY-MM-DD",
        )}`,
        stepCssClass: "has-step-green",
        boxCssClass: "has-color-red",
      }
      const obj2 = {
        title: "Supervisor",
        content:
          item.material_request_is_accepted_by_supervisor === true
            ? `Material Request Has been Accepted by ${
                item.supervisor.user_fullname
              } at ${this.dateSimple(
                item.material_request_is_accepted_by_supervisor_date,
                "YYYY-MM-DD",
              )}`
            : `Material Request Has not been Accepted By Supervisor`,
        stepCssClass:
          item.material_request_is_accepted_by_supervisor === true
            ? "has-step-green"
            : "has-step-red",
      }
      let obj3 = null
      let obj4 = null
      if (item.material_request_is_center === true) {
        obj3 = {
          title: "Purchase Request",
          content:
            item.material_request_is_created_in_pr === true
              ? `Purchase Request Has been Accepted by ${
                  item.purchase_request_creator.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_created_in_pr_date,
                  "YYYY-MM-DD",
                )}`
              : `Purchase Request Has not been Created`,
          stepCssClass:
            item.material_request_is_created_in_pr === true
              ? "has-step-green"
              : "has-step-red",
        }
        obj4 = {
          title: "Purchase Order",
          content:
            item.material_request_is_created_in_po === true
              ? `Purchase Order Has been created By ${
                  item.purchase_order_creator.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_created_in_po_date,
                  "YYYY-MM-DD",
                )}`
              : `Purchase Order Has not been Created`,
          stepCssClass:
            item.material_request_is_created_in_po === true
              ? "has-step-green"
              : "has-step-red",
        }
      } else {
        obj3 = {
          title: "Warehouse Cabang",
          content:
            item.material_request_is_accepted_by_warehouse === true
              ? `Material Request Has been Accepted by ${
                  item.sender.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_accepted_by_warehouse_date,
                  "YYYY-MM-DD",
                )}`
              : `Material Request Has not been Accepted By Warehouse`,
          stepCssClass:
            item.material_request_is_accepted_by_warehouse === true
              ? "has-step-green"
              : "has-step-red",
        }
        obj4 = {
          title: "Received",
          content:
            item.material_request_is_received === true
              ? `Material Request Has been Received By ${
                  item.receiver.user_fullname
                } at ${this.dateSimple(
                  item.material_request_is_received_date,
                  "YYYY-MM-DD",
                )}`
              : `Material Request Has not been Received`,
          stepCssClass:
            item.material_request_is_received === true
              ? "has-step-green"
              : "has-step-red",
        }
      }
      this.statusItems = []
      this.statusItems.push(obj1)
      this.statusItems.push(obj2)
      this.statusItems.push(obj3)
      this.statusItems.push(obj4)
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_DETAILS}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const joined = []
            response.data.data.map(elem => {
              const temp = {
                id: elem.material_request_detail_id,
                label: `${elem.item.item_name} (${elem.item.item_code})`,
                value: elem.material_request_detail_item_id,
                item: elem.item,
                qty: elem.material_request_detail_item_qty_requested,
                qty_accepted: elem.material_request_detail_item_qty_accepted > 0 ? elem.material_request_detail_item_qty_accepted : elem.material_request_detail_item_qty_requested,
                unit: elem.item.unit,
              }
              joined.push(temp)
            })
            this.items = joined
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    submitEditMaterialRequest() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      this.submitButtonDisabled = true
      this.materialRequestQtyAccepted = 0
      let numberFilled = true
      this.items.map(elem => {
        this.materialRequestQtyAccepted += parseInt(elem.qty_accepted)
      })
      this.items.map(o => {
        if (o.qty_accepted === "") {
          numberFilled = false
        }
      })
      if (numberFilled === true) {
        const body = {
          material_request_id: this.materialRequestId,
          material_request_to: this.materialRequestTo,
          material_request_cc: this.materialRequestCc,
          material_request_reason: this.materialRequestReason,
          material_request_qty_accepted: this.materialRequestQtyAccepted,
          material_request_is_accepted_by_supervisor: true,
          material_request_items: JSON.stringify(this.items),
          time: moment(),
        }
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_MATERIAL_REQUEST}`
      axios
        .post(url, body, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.items = []
            this.materialRequestQtyAccepted = 0
            this.materialRequestReason = ""
            this.materialRequestCc = ""
            this.materialRequestTo = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Material Request Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-material-request-list' })
          } else {
      this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Material Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
      this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Material Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      } else {
      this.submitButtonDisabled = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Please Fill all the numbers",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
      }
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
        previous() {
            this.$router.push({ name: 'apps-material-request-list' })
        },
    capitalizeWords(str) {
      // Split the string into words using spaces as the separator
      const words = str.split(" ")
      // Capitalize the first letter of each word and join them back together
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      // Join the capitalized words into a single string with spaces
      return capitalizedWords.join(" ")
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    },
  }
  </script>
